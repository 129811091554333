import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Header from './components/common/Header';
import ProfilePage from './screen/Profile';
import Login from './screen/Login';
import Signup from './screen/Signup';
import Dashboard from './screen/Dashboard';
import Establishments from './screen/Establishments';
import Establishment from './screen/Establishment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types'; // Importamos PropTypes

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('authToken');
  return isAuthenticated ? children : <Navigate to="/login" />;
};

// PropTypes validation for children
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const AppContent = () => {
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem('authToken'); // Verificar autenticación

  // Condicionar el renderizado del Header según la página
  const showHeader = location.pathname !== '/login' && location.pathname !== '/signup';

  return (
    <div className="App">
      {showHeader && <Header />}
      
      <Routes>
        {/* Redirigir automáticamente desde la raíz */}
        <Route 
          path="/" 
          element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} 
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>} />
        <Route path="/dashboard/establecimientos" element={
          <PrivateRoute>
            <Establishments />
          </PrivateRoute>} />
        <Route path="/dashboard/establecimiento/:id/*" element={
          <PrivateRoute>
            <Establishment />
          </PrivateRoute>} />
        <Route path="/profile" element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>} />
      </Routes>

      <ToastContainer />
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;