import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import api from '../../api/axiosConfig';
import './EstablishmentsList.css';
import editIcon from '../../assets/edit-icon.png'; // Icono de editar
import deleteIcon from '../../assets/delete-icon.png'; // Asegúrate de tener este ícono en tu carpeta de assets
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

const EstablishmentsList = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [establishments, setEstablishments] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para manejar la carga
  const [isSearchClicked, setIsSearchClicked] = useState(false); // Estado para manejar si la búsqueda fue iniciada

  useImperativeHandle(ref, () => ({
    refreshList() {
      fetchEstablishments();
    },
    // Función para indicar que la búsqueda fue iniciada
    handleSearchClick() {
      setIsSearchClicked(true); // Cambiar el estado a true cuando se haga clic en la lupa
    },
  }));

  const fetchEstablishments = async () => {
    setIsLoading(true); // Establecer isLoading en true mientras se carga la lista
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/establishments', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEstablishments(response.data);
    } catch (error) {
      console.error('Error al obtener los establecimientos:', error);
      alert('Hubo un problema al obtener los establecimientos. Por favor, inténtelo de nuevo.');
    } finally {
      setIsLoading(false); // Establecer isLoading en false cuando la carga haya terminado
    }
  };

  useEffect(() => {
    fetchEstablishments();
  }, []);

  const handleEditClick = (establishment) => {
    props.onEdit(establishment);
  };

  const handleDeleteClick = (establishment) => {
    props.onDelete(establishment);
  };

  const handleRowClick = (event, establishmentId) => {
    // Evitar que el click en los botones de Editar/Eliminar dispare la navegación
    if (event.target.tagName !== 'BUTTON' && event.target.tagName !== 'IMG') {
      navigate(`/dashboard/establecimiento/${establishmentId}/details`);
    }
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.cursor = 'pointer';
  };

  return (
    <div className="establishments-list-container">
      {isLoading ? ( // Mostrar un mensaje de carga mientras se obtienen los datos
        <p>Cargando establecimientos...</p>
      ) : establishments.length === 0 && isSearchClicked ? ( // Mostrar mensaje de "No se encontraron" solo si no hay resultados y el usuario hizo clic en la búsqueda
        <p>No se encontraron establecimientos con ese nombre.</p>
      ) : (
        <table className="establishments-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Dirección</th>
              <th>Localidad</th>
              <th>Provincia</th>
              <th>Rubro</th>
              <th>Información de Contacto</th>
              <th>Fecha de Creación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {props.establishments.map((establishment) => (
              <tr
                key={establishment._id}
                className="establishment-row"
                onMouseEnter={handleMouseEnter}
                onClick={(event) => handleRowClick(event, establishment._id)}
              >
                <td>{establishment.name}</td>
                <td>{establishment.address}</td>
                <td>{establishment.city}</td>
                <td>{establishment.province}</td>
                <td>{establishment.industry}</td>
                <td>{establishment.contactInfo}</td>
                <td>{new Date(establishment.createdAt).toLocaleDateString()}</td>
                <td>
                  <Tooltip title="Editar">
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Evitar que el click en el botón propague el evento al click de la fila
                        handleEditClick(establishment);
                      }}
                    >
                      <img src={editIcon} alt="Editar" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Evitar que el click en el botón propague el evento al click de la fila
                        handleDeleteClick(establishment);
                      }}
                    >
                      <img src={deleteIcon} alt="Eliminar" />
                    </button>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
});

EstablishmentsList.displayName = 'EstablishmentsList';

EstablishmentsList.propTypes = {
  establishments: PropTypes.array.isRequired,  // Propiedad requerida para los establecimientos filtrados
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EstablishmentsList;
