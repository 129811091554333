import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import profileIcon from '../../assets/profile-icon.png';
import hamburgerIcon from '../../assets/hamburger-icon.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    console.log('Hamburger menu clicked');
    setMenuOpen((prevState) => !prevState);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Cerrar el menú si el usuario hace clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-menu') && !event.target.closest('.hamburger-button')) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-left">
        <h1>BioSafeApp</h1>
      </div>
      <div className="header-right">
        <button className="hamburger-button" onClick={toggleMenu}>
          <img src={hamburgerIcon} alt="Menu" />
        </button>

        {menuOpen && (
          <div className="dropdown-menu">
            <Link to="/profile" className="dropdown-item" onClick={closeMenu}>
              <img src={profileIcon} alt="Perfil" className="dropdown-icon" />
              Perfil
            </Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;