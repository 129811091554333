import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Asegúrate de importar PropTypes
import jsPDF from 'jspdf'; // Importamos jsPDF
import 'jspdf-autotable'; // Importamos la extensión para tablas
import './FormDetails.css'; // Asegúrate de tener este archivo CSS
import api from '../../api/axiosConfig'; // Importamos la API para obtener datos del usuario

const FormDetails = ({ form, onBack }) => {
  const [companyLogo, setCompanyLogo] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [userName, setUserName] = useState('N/A');
  const [licenseNumber, setLicenseNumber] = useState('N/A');

  useEffect(() => {
    // Función para obtener la información del usuario y cargar el logo y firma
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get('/api/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setCompanyLogo(userData.companyLogo || null);
        setSignatureImage(userData.signatureImage || null);
        setUserName(`${userData.name} ${userData.surname}`);
        setLicenseNumber(userData.licenseNumber || 'N/A');
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  // Función para convertir una imagen de URL a base64
  const loadImageAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Evitar problemas de CORS
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => reject(error);
    });
  };

  // Función para generar el PDF
  const handleDownloadPDF = async () => {
    const doc = new jsPDF();
  
    // Establecemos una fuente consistente para evitar problemas de renderizado
    doc.setFont('helvetica', 'normal');
  
    // Cargar el logo y la firma en base64 antes de continuar
    let logoBase64 = null;
    let signatureBase64 = null;
  
    try {
      // Cargar el logo si está disponible
      if (companyLogo) {
        logoBase64 = await loadImageAsBase64(companyLogo);
      }
      // Cargar la firma si está disponible
      if (signatureImage) {
        signatureBase64 = await loadImageAsBase64(signatureImage);
      }
    } catch (error) {
      console.error('Error loading images:', error);
    }
  
    // Agregar el logo en la esquina superior izquierda con las dimensiones ajustadas
    if (logoBase64) {
      const img = new Image();
      img.src = logoBase64;
  
      img.onload = () => {
        const maxWidth = 99.2; // 375 px -> 99.2 mm
        const maxHeight = 27.7; // 105 px -> 27.7 mm
        const imgWidth = img.width;
        const imgHeight = img.height;
  
        // Calcular la relación de aspecto de la imagen
        const aspectRatio = imgWidth / imgHeight;
  
        let finalWidth = maxWidth;
        let finalHeight = maxHeight;
  
        // Ajustar el tamaño manteniendo la relación de aspecto
        if (aspectRatio > 1) { // Imagen más ancha que alta
          finalHeight = Math.min(maxHeight, maxWidth / aspectRatio);
        } else { // Imagen más alta o cuadrada
          finalWidth = Math.min(maxWidth, maxHeight * aspectRatio);
        }
  
        // Agregar la imagen ajustada al documento PDF
        doc.addImage(logoBase64, 'PNG', 10, 10, finalWidth, finalHeight);
  
        // Continuar con el resto del PDF
        // Añadir el título del formulario después del logo
        doc.setFontSize(18);
        doc.text(form.title, 10, 50); // Colocar el título debajo del logo
  
        // Añadir la descripción si existe
        doc.setFontSize(12);
        doc.text(form.description || 'Sin descripción', 10, 60);
  
        // Generar una tabla con los campos y valores del formulario
        const tableData = form.fields.map((field) => [field.label || field.name, field.value]);
  
        doc.autoTable({
          head: [['Campo', 'Valor']],
          body: tableData,
          startY: 70, // Ubicar la tabla debajo del contenido previo
        });
  
        // Mostrar nombre completo y número de matrícula al final del documento
        const finalY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 20 : 100;
  
        // Agregar la firma en la esquina inferior derecha si existe
        if (signatureBase64) {
          doc.addImage(signatureBase64, 'PNG', 140, finalY, 50, 30); // Firma en la esquina inferior derecha
  
          // Después de la firma, añadir nombre y matrícula
          doc.setFontSize(12);
          doc.text(`Nombre: ${userName}`, 140, finalY + 35); // Debajo de la firma
          doc.text(`Número de matrícula: ${licenseNumber}`, 140, finalY + 45); // Justo debajo del nombre
        } else {
          // Si no hay firma, solo mostrar el nombre y matrícula
          doc.setFontSize(12);
          doc.text(`Nombre: ${userName}`, 10, finalY + 20);
          doc.text(`Número de matrícula: ${licenseNumber}`, 10, finalY + 30);
        }
  
        // Obtener la fecha de creación del formulario
        const creationDate = new Date(form.createdAt).toLocaleDateString('es-ES');
  
        // Descargamos el PDF
        doc.save(`${form.title} ${creationDate}.pdf`);
      };
    }
  };

  return (
    <div className="form-details">
      <div className="button-container">
        <button onClick={onBack} className="back-button">Volver</button>
        <button onClick={handleDownloadPDF} className="download-button">Descargar</button>
      </div>
      <h2>{form.title}</h2>
      <p>{form.description || 'Sin descripción'}</p>

      <table className="form-fields">
        <thead>
          <tr>
            <th>Campo</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {form.fields.map((field) => (
            <tr key={field._id}>
              <td>{field.label || field.name}</td>
              <td>{field.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Definición de PropTypes para validar las propiedades recibidas
FormDetails.propTypes = {
  form: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ).isRequired,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
};

export default FormDetails;