import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PaginatedForm from '../common/PaginatedForm';
import formRMEJson from '../../data/RMEForm.json';
import formSGCJson from '../../data/SGCForm.json';

const FormModal = ({ establishmentId, onFormCreated, onClose, formToEdit }) => {
  const [selectedForm, setSelectedForm] = useState(null);

  useEffect(() => {
    if (formToEdit) {
      // Si se está editando un formulario, automáticamente selecciona el tipo de formulario basado en el formToEdit
      if (formToEdit.title === 'Formulario RME') {
        setSelectedForm(formRMEJson);
      } else if (formToEdit.title === 'Formulario SGC') {
        setSelectedForm(formSGCJson);
      }
    }
  }, [formToEdit]);

  const handleOptionClick = (option) => {
    if (option === 'Formulario RME') {
      setSelectedForm(formRMEJson);
    }
    if (option === 'Formulario SGC') {
      setSelectedForm(formSGCJson);
    }
    // Puedes agregar más opciones aquí si tienes otros formularios
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        {!selectedForm && !formToEdit ? (
          <div>
            <h2>Selecciona el tipo de formulario</h2>
            <div className="modal-buttons">
              <button className="modal-button" onClick={() => handleOptionClick('Formulario RME')}>
                Formulario RME
              </button>
              <button className="modal-button" onClick={() => handleOptionClick('Formulario SGC')}>
                Formulario SGC
              </button>
            </div>
          </div>
        ) : (
          <PaginatedForm 
            establishmentId={establishmentId}  // Pasando el establishmentId correctamente
            formJson={selectedForm} 
            onClose={onClose} 
            onFormCreated={onFormCreated}
            formToEdit={formToEdit} // Pasamos el formulario a editar si existe
          />
        )}
      </div>
    </div>
  );
};

FormModal.propTypes = {
  establishmentId: PropTypes.string.isRequired,
  onFormCreated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  formToEdit: PropTypes.object, // Es opcional porque puede ser nulo si se está creando un nuevo formulario
};

export default FormModal;
