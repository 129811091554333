import React, {  useState, useEffect } from 'react';
import Sidebar from '../components/common/Sidebar';
import AddEstablishmentButton from '../components//establishments/AddEstablishmentButton';
import EstablishmentsList from '../components/establishments/EstablishmentsList';
import Tooltip from '@mui/material/Tooltip';
import api from '../api/axiosConfig';
import lupaIcon from '../assets/lupa.png';
import './Dashboard.css';

const Establishments = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);
  const [establishments, setEstablishments] = useState([]);  // Estado para guardar los establecimientos cargados
  const [filteredEstablishments, setFilteredEstablishments] = useState([]); // Estado para los resultados filtrados
  const [searchTerm, setSearchTerm] = useState(''); // Término de búsqueda
  const [isSearchClicked, setIsSearchClicked] = useState(false); // Estado para manejar si el usuario hizo clic en la búsqueda

  useEffect(() => {
    fetchEstablishments(); // Cargar establecimientos cuando el componente se monta
  }, []);

  const fetchEstablishments = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/establishments', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEstablishments(response.data);
      setFilteredEstablishments(response.data); // Mostrar todos los establecimientos al inicio
    } catch (error) {
      console.error('Error al cargar los establecimientos:', error);
    }
  };

  const handleSearch = () => {
    setIsSearchClicked(true); // Marcar que se ha realizado una búsqueda

    if (searchTerm.trim() === '') {
      setFilteredEstablishments(establishments); // Si no hay término de búsqueda, mostrar todos
    } else {
      const filtered = establishments.filter(establishment =>
        establishment.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredEstablishments(filtered);
    }
  };

  // Esta función se llamará cuando el usuario presione "Enter"
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch(); // Ejecutar la búsqueda al presionar Enter
    }
  };

  const handleEdit = (establishment) => {
    setSelectedEstablishment(establishment);
    setIsEditing(true);
  };

  const handleSaveChanges = async (updatedData) => {
    const confirmUpdate = window.confirm(`¿Estás seguro que quieres editar el establecimiento ${selectedEstablishment.name}?`);
    if (!confirmUpdate) return;

    try {
      const token = localStorage.getItem('authToken');
      await api.put(`/api/establishments/${selectedEstablishment._id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Establecimiento actualizado con éxito');
      fetchEstablishments(); // Recargar la lista después de editar
      setIsEditing(false);
      setSelectedEstablishment(null);
    } catch (error) {
      console.error('Error al actualizar el establecimiento:', error);
      alert('Hubo un problema al actualizar el establecimiento. Por favor, inténtelo de nuevo.');
    }
  };

  const handleDelete = async (establishment) => {
    const confirmDelete = window.confirm(`¿Estás seguro que quieres eliminar el establecimiento ${establishment.name}?`);
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem('authToken');
      await api.delete(`/api/establishments/${establishment._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Establecimiento eliminado con éxito');
      fetchEstablishments(); // Recargar la lista después de eliminar
    } catch (error) {
      console.error('Error al eliminar el establecimiento:', error);
      alert('Hubo un problema al eliminar el establecimiento. Por favor, inténtelo de nuevo.');
    }
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <h2>Página de Establecimientos</h2>
        <AddEstablishmentButton
          refreshList={fetchEstablishments}
          initialData={isEditing ? selectedEstablishment : null}
          isEditing={isEditing}
          onSave={handleSaveChanges}
          onCancel={() => setIsEditing(false)}
        />

        {/* Búsqueda por nombre con lupa */}
        <div className="search-container">
          <input
            type="text"
            placeholder="Buscar por nombre..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={handleKeyPress}  // Ejecutar búsqueda al presionar Enter
          />
          <Tooltip title="Buscar">
            <button onClick={handleSearch}>
              <img src={lupaIcon} alt="Buscar" className="search-icon" />
            </button>
          </Tooltip>
        </div>

        {/* Mostrar la lista de establecimientos filtrados */}
        <EstablishmentsList
          establishments={filteredEstablishments}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />

        {/* Mostrar mensaje si no hay resultados y el usuario hizo clic en la lupa */}
        {isSearchClicked && filteredEstablishments.length === 0 && (
          <p>No se encontraron establecimientos.</p>
        )}
      </div>
    </div>
  );
};

export default Establishments;
