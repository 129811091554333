import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './EstablishmentForm.css';
import provinces from '../../utils/provinces';

const EstablishmentForm = ({ onSubmit, initialData, isEditing }) => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    province: '',
    industry: '',
    contactInfo: '',
  });

  const [localidades, setLocalidades] = useState([]);
  const [showOtherCity, setShowOtherCity] = useState(false);
  const [errors, setErrors] = useState({});

  // Función para obtener localidades basado en la provincia seleccionada
  const fetchLocalidades = useCallback(async (province) => {
    try {
      const response = await fetch(
        `https://apis.datos.gob.ar/georef/api/localidades?provincia=${province}&campos=id,nombre&max=5000`
      );
      const data = await response.json();

      if (data && data.localidades) {
        const sortedLocalidades = data.localidades
          .map((loc) => loc.nombre)
          .sort((a, b) => a.localeCompare(b));
        setLocalidades(sortedLocalidades);
      } else {
        setLocalidades([]);
      }
    } catch (error) {
      console.error('Error al obtener las localidades:', error);
      setLocalidades([]);
    }
  }, []);

  // Maneja el cambio de provincia y actualiza el estado correspondiente
  const handleProvinceChange = async (province) => {
    setFormData((prevData) => ({
      ...prevData,
      province,
      city: '', // Limpiamos la ciudad cuando cambia la provincia
    }));

    setShowOtherCity(false); // Ocultamos el campo "Otra Localidad" al cambiar de provincia
    await fetchLocalidades(province); // Obtenemos las localidades correspondientes
  };

  // Maneja el cambio de ciudad y actualiza el estado correspondiente
  const handleCityChange = (e) => {
    const { value } = e.target;
    if (value === 'Otros') {
      setShowOtherCity(true);
      setFormData((prevData) => ({
        ...prevData,
        city: '',
      }));
    } else {
      setShowOtherCity(false);
      setFormData((prevData) => ({
        ...prevData,
        city: value,
      }));
    }
  };

  // Maneja cambios generales en los campos de texto
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Carga de datos iniciales cuando el formulario se utiliza para editar
  useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name || '',
        address: initialData.address || '',
        city: initialData.city || '',
        province: initialData.province || '',
        industry: initialData.industry || '',
        contactInfo: initialData.contactInfo || '',
      });

      if (initialData.province) {
        fetchLocalidades(initialData.province).then(() => {
          if (
            initialData.city &&
            !localidades.includes(initialData.city)
          ) {
            setShowOtherCity(true);
          }
        });
      }
    }
  }, [initialData, fetchLocalidades]);

  // Validación del formulario antes de enviar
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name || formData.name.trim() === '') {
      newErrors.name = 'El nombre es obligatorio';
    } else if (formData.name.length < 3) {
      newErrors.name = 'El nombre debe tener al menos 3 caracteres';
    }

    if (!formData.address || formData.address.trim() === '') {
      newErrors.address = 'La dirección es obligatoria';
    } else if (formData.address.length < 5) {
      newErrors.address = 'La dirección debe tener al menos 5 caracteres';
    }

    if (!formData.province || formData.province.trim() === '') {
      newErrors.province = 'La provincia es obligatoria';
    }

    if (showOtherCity) {
      if (!formData.city || formData.city.trim() === '') {
        newErrors.city = 'Otra Localidad es obligatoria';
      } else if (formData.city.length < 3) {
        newErrors.city = 'La localidad debe tener al menos 3 caracteres';
      }
    } else {
      if (!formData.city || formData.city.trim() === '') {
        newErrors.city = 'La localidad es obligatoria';
      }
    }

    if (!formData.industry || formData.industry.trim() === '') {
      newErrors.industry = 'El rubro es obligatorio';
    } else if (formData.industry.length < 3) {
      newErrors.industry = 'El rubro debe tener al menos 3 caracteres';
    }

    if (!formData.contactInfo || formData.contactInfo.trim() === '') {
      newErrors.contactInfo = 'La información de contacto es obligatoria';
    } else if (formData.contactInfo.length < 3) {
      newErrors.contactInfo =
        'La información de contacto debe tener al menos 3 caracteres';
    }

    return newErrors;
  };

  // Maneja el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      const finalData = {
        ...formData,
        city: showOtherCity ? formData.city : formData.city,
      };
      onSubmit(finalData);
    }
  };

  return (
    <form className="establishment-form" onSubmit={handleSubmit}>
      <div>
        <label>Nombre del establecimiento</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className={errors.name ? 'input-error' : ''}
        />
        {errors.name && (
          <span className="error-text">{errors.name}</span>
        )}
      </div>

      <div>
        <label>Dirección</label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          className={errors.address ? 'input-error' : ''}
        />
        {errors.address && (
          <span className="error-text">{errors.address}</span>
        )}
      </div>

      <div>
        <label>Provincia</label>
        <select
          data-testid="province-select"
          name="province"
          value={formData.province}
          onChange={(e) => handleProvinceChange(e.target.value)}
          className={errors.province ? 'input-error' : ''}
        >
          <option value="">Seleccione una provincia</option>
          {provinces.map((province, index) => (
            <option key={index} value={province}>
              {province}
            </option>
          ))}
        </select>
        {errors.province && (
          <span className="error-text">{errors.province}</span>
        )}
      </div>

      <div>
        <label>Localidad</label>
        <select
          name="city"
          value={
            showOtherCity
              ? 'Otros'
              : formData.city || ''
          }
          onChange={handleCityChange}
          className={errors.city ? 'input-error' : ''}
          disabled={!formData.province}
        >
          <option value="">Seleccione una localidad</option>
          {localidades.map((localidad, index) => (
            <option key={index} value={localidad}>
              {localidad}
            </option>
          ))}
          <option value="Otros">Otros</option>
        </select>
        {errors.city && !showOtherCity && (
          <span className="error-text">{errors.city}</span>
        )}
      </div>

      {showOtherCity && (
        <div>
          <label>Otra Localidad</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className={errors.city ? 'input-error' : ''}
          />
          {errors.city && (
            <span className="error-text">{errors.city}</span>
          )}
        </div>
      )}

      <div>
        <label>Rubro</label>
        <input
          type="text"
          name="industry"
          value={formData.industry}
          onChange={handleChange}
          className={errors.industry ? 'input-error' : ''}
        />
        {errors.industry && (
          <span className="error-text">{errors.industry}</span>
        )}
      </div>

      <div>
        <label>Información de contacto</label>
        <input
          type="text"
          name="contactInfo"
          value={formData.contactInfo}
          onChange={handleChange}
          className={errors.contactInfo ? 'input-error' : ''}
        />
        {errors.contactInfo && (
          <span className="error-text">
            {errors.contactInfo}
          </span>
        )}
      </div>

      <button type="submit">
        {isEditing
          ? 'Actualizar Establecimiento'
          : 'Crear Establecimiento'}
      </button>
    </form>
  );
};

EstablishmentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  isEditing: PropTypes.bool,
};

EstablishmentForm.defaultProps = {
  initialData: null,
  isEditing: false,
};

export default EstablishmentForm;
